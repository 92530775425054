import sha256 from "js-sha256";
import "babel-polyfill";

export const SIRI_MAPPING = {
    // SIR values from
    // https://bitbucket.org/astappteam/expert-system/src/master/packages/expert-system/src/adapters/services/I2AExpertiseService.ts.
    // If the Expert System does not change the SIR value, it keeps the original
    // one. Also list values which don't change to make it possible to detect unknown
    // keys.
    S: 'S',
    I: 'I',
    R: 'R',
    NI: 'N/A',
    PS: 'N/A',

    // SIRI values from rules.json:
    'Diminished susceptibility': 'I',
    'High level resistance': 'R',
    'Intermediate level resistance': 'R',
    Intermediate: 'I',
    'Low level resistance': 'R',
    Resistant: 'R',
    SUSCEPTIBLE: 'S',
    'SUSCEPTIBLE, INCREASED EXPOSURE': 'S',
    'Susceptible "with reserve"': 'S',
    '.': 'N/A',
    'Cannot be interpeted': 'N/A',
    'In progress': 'N/A',
    'MIC = @mic mg/l': 'N/A',
    'NOT CORRECT': 'N/A',
    'No Eucast Breakpoint': 'N/A',
    'request M.I.C.': 'N/A',
};

export class AST {
  // Class dedicated to log analysis, takes an array of logs for a specific AST
  // and turns it into an object that's easier to manipulate
  constructor(logs, candidateAstId) {
    try {
      // sort the array of logs in chronological order
      logs.sort(function (log1, log2) {
        var t1 = log1.timestampMs.valueOf();
        var t2 = log2.timestampMs.valueOf();

        if (t1 < t2) {
          return -1;
        }

        if (t2 > t1) {
          return 1;
        }

        return 0;
      });
      this.startTime = logs[0].timestampMs.toDate();
      this.endTime = logs[logs.length - 1].timestampMs.toDate();
      this.logs = logs;
      this.added = 0;
      this.removed = 0;
      this.isKotlin = false;
      this.userDeleted = false;  // Whether or not the user has deleted this AST.
      this.astIdHash = logs[0].astIdHash;
      this.sessionId = logs[0].sessionId;
      if (candidateAstId && sha256(candidateAstId) == this.astIdHash) {
        this.astId = candidateAstId;
      }
      for (let i = 0; i < logs.length; i++) {
        const payload = logs[i].logEntry.payload;
        switch (logs[i].logEntry.action) {
          case "HELLO_KOTLIN":
            this.isKotlin = true;
            break;
          case "form/setSpecies":
            this.species = payload.name;
            this.genus = payload.genus;
            this.groups = payload.groups.map(
              group => group.name
            );
            break;
          case "form/setUserName":
            this.userName = payload;
            break;
          case "form/setSampleType":
            this.sampleType = payload;
            break;
          case "croppedPicture/set":
            this.cropOffset = payload;
            break;
          case "networkTask/succeeded":
            this.imageFile = payload.cloudRef;
            break;
          case "ANTIBIOTICS_STATE::antibiotics/set":
            this.initialAntibiotics = payload.antibiotics;
            this.pixelPerMM = payload.pxPerMm;
            break;
          case "antibiotics/add":
            this.added += 1;
            break;
          case "antibiotics/removeSelected":
            this.removed += 1;
            break;
          case "ANTIBIOTICS_STATE::ES/startFlow":
            this.preESAntibiotics = payload.antibiotics;
            break;
          case "ES/startFlow":
            this.ESSequence = [];
            this.numQuestions = 0;
            break;
          case "ES/beforeRunExpertise":
            this.ESSequence.push(payload);
            break;
          case "ES/promptFromES":
            this.numQuestions += 1;
            this.ESSequence.push(payload);
            break;
          // userAnsweredESQuestion was renamed to answeredESQuestion.
          // Keep both for backward-compatibility.
          case "ES/userAnsweredESQuestion":
          case "ES/answeredESQuestion":
            this.ESSequence.push(payload);
            break;
          case "ANTIBIOTICS_STATE::ES/completeFlow":
            this.finalAntibiotics = payload.antibiotics;
            break;
          case "ES/completeFlow":
            if (typeof payload.antibiogram === "string") {
              // Kotlin sends it as a JSON-serialised string, RN sends it as an object.
              this.finalAntibiogram = JSON.parse(payload.antibiogram);
            } else {
              this.finalAntibiogram = payload.antibiogram;
            }

            break;
          case "setAstCompleted":
            // an edit in the AST invalidated the previously completed AST
            if (!payload) {
              this.ESSequence = [];
              this.numQuestions = 0;
              this.finalAntibiotics = undefined;
              this.finalAntibiogram = undefined;
            }
            break;
          case "deleteAstById":
            // The user has deleted this AST. We should ignore it.
            // https://github.com/foundationmsf/webconsole/issues/3
            this.userDeleted = true;
            break;
        }
      }
    } catch (err) {
      console.error(err);
      throw Error("Wrong log format");
    }
  }

  getImageUrl() {
    if (!this.imageUrl) {
      this.imageUrl = this.imageFile
        ? "https://storage.cloud.google.com/astapp-6b3bc.appspot.com/" +
          this.imageFile
        : "NOT YET UPLOADED";
    }

    return this.imageUrl;
  }

  get complete() {
    return (
      this.species &&
      this.sampleType &&
      this.cropOffset &&
      this.pixelPerMM &&
      this.initialAntibiotics &&
      this.preESAntibiotics &&
      this.finalAntibiotics &&
      this.finalAntibiogram &&
      this.ESSequence &&
      !this.userDeleted
    );
  }

  get completionTime() {
    if (this.complete) {
      return this.endTime - this.startTime;
    }
  }
}

export function logsToAsts(logData, astIds) {
  /* Takes the result of a firestore query, segregates the logs per
    AST id and session, build AST objects and return them as an array. */
  if (!astIds) {
    astIds = [];
  }
  let astIdHashMap = {};
  astIds.forEach(id => (astIdHashMap[sha256(id)] = id));
  let astLogs = {};
  logData.forEach(function(log) {
    let key = [log.astIdHash, log.sessionId];
    if (key in astLogs) {
      astLogs[key].push(log);
    } else {
      astLogs[key] = [log];
    }
  });
  let asts = [];
  Object.entries(astLogs).forEach(function([key, logs]) {
    // to turn an array into an object key, javascript turns each value
    // into a string and concatenate with a comma separator, split allows
    // us to retrieve a specific item as long as it's not a character used
    let astIdHash = key.split(",")[0];
    asts.push(new AST(logs, astIdHashMap[astIdHash]));
  });
  return asts;
}

const checkMappedSiri = (antibiogram, antibiotic, values) =>
  antibiotic in antibiogram.antibiotics &&
  values.includes(SIRI_MAPPING[antibiogram.antibiotics[antibiotic].siri]);

export function extractMechanismFromAst(ast) {
  /* Takes an AST object and returns an array of pairs,
            the first item is the name of the column, the second item is
            the value from the expert system. Mechanism of interest are defined
            per group or genus of bacteria */

  let mechanisms = [];

  // Staphylococcus mechanisms
  if (ast.genus.match("Staphylococcus")) {
    let constitutiveMlsb = false;
    let inducibleMlsb = false;
    let efflux = false;
    let mlsbOrEfflux = false;
    let mrsa = false;

    const visa = checkMappedSiri(ast.finalAntibiogram, "VANCOMYCIN 5µg", [
      "I",
      "R"
    ]);

    Object.entries(ast.finalAntibiogram.tests).forEach(function(entry) {
      const test = entry[0];
      const value = entry[1];
      if (test == "STAPH & B-LACTAMS") {
        if (value == "METHI-R") {
          mrsa = true;
        }
      }

      if (test == "STAPH : MLS PHENOTYPES") {
        switch (value) {
          case "Constitutive MLSb":
            constitutiveMlsb = true;
            break;
          case "Inducible MLSb":
            inducibleMlsb = true;
            break;
          case "Efflux":
            efflux = true;
            break;
          case "Inducible MSLb or efflux":
            mlsbOrEfflux = true;
            break;
        }
      }
    });
    mechanisms.push([
      "Vancomycin Intermediaire ou Résistant Staphylococcus Aureus",
      visa
    ]);
    mechanisms.push(["MLSb constitutif", constitutiveMlsb]);
    mechanisms.push(["MLSb inductible", inducibleMlsb]);
    mechanisms.push(["Efflux", efflux]);
    mechanisms.push(["MLSb inductible ou Efflux", mlsbOrEfflux]);
    mechanisms.push(["MRSA", mrsa]);
    //enterobacteria mechanism
  } else if (ast.groups.includes("Enterobacterales")) {
    let esbl = false;
    let cephalosporinase = false;
    let highCephalosporinase = false;
    let penicillinase = false;
    let lowPenicillinase = false;
    let highPenicillinase = false;
    let highBetaLactamase = false;
    let carbapenemase = false;

    Object.entries(ast.finalAntibiogram.tests).forEach(function(entry) {
      const test = entry[0];
      const value = entry[1];

      if (test == "Enterobacteriacceae & Blactams") {
        switch (value) {
          case "LOW LEVEL PENICILLINASE":
            lowPenicillinase = true;
            break;
          case "HIGH LEVEL PASE":
            highPenicillinase = true;
            break;
          case "CEPHALOSPORINASE":
            cephalosporinase = true;
            break;
          case "CEPHALOSPORINASE + PNASE":
            cephalosporinase = true;
            penicillinase = true;
            break;
          case "E.S.B.L.":
            esbl = true;
            break;
          case "Hyper produced beta lacatamase":
            highBetaLactamase = true;
            break;
          case "IRT+CASE":
            cephalosporinase = true;
            break;
          case "ESBL SUSPICION":
            esbl = true;
            break;
          case "Chromosomal ESBL":
            esbl = true;
            break;
          case "ESBL+HIGH LEVEL CEPHALOSPORINASE":
            esbl = true;
            highCephalosporinase = true;
            break;
          case "ESBL SUSPICION + HIGH LEVEL CASE":
            esbl = true;
            highCephalosporinase = true;
            break;
          case "ESBL + HIGH LEVEL B-LACTAMASE":
            esbl = true;
            highBetaLactamase = true;
            break;
          case "Probable hyperproducing B lactamase":
            highBetaLactamase = true;
            break;
        }
      }

      if (test == "Production of carbapenemase") {
        if (value == "Yes") {
          carbapenemase = true;
        }
      }
    });

    mechanisms.push(["ESBL", esbl]);
    mechanisms.push(["Céphalosporinase", cephalosporinase]);
    mechanisms.push(["High level céphalosporinase", highCephalosporinase]);
    mechanisms.push(["Pénicillinase", penicillinase]);
    mechanisms.push(["Pénicillinase bas niveau", lowPenicillinase]);
    mechanisms.push(["Pénicillinase haut niveau", highPenicillinase]);
    mechanisms.push(["Beta lactamase haut niveau", highBetaLactamase]);
    mechanisms.push(["Carbapénémase", carbapenemase]);

    // Non fermenting bacteria including pseudomonas
  } else if (ast.groups.includes("Not fermenting gram neg bacilli")) {
    let highCephalosporinase = false;
    let cephalosporinase = false;
    let esbl = false;
    let oprd2 = false;
    let nonEnzymaticResistance = false;
    let carbapenemase = false;

    Object.entries(ast.finalAntibiogram.tests).forEach(function(entry) {
      const test = entry[0];
      const value = entry[1];

      if (test == "P.AERUGINOSA & B-LACTAMS") {
        switch (value) {
          case "HYPERPROD OF CEPHALOSPORINASE":
            highCephalosporinase = true;
            break;
          case "HIGH LEVEL CEPHALOSPORINASE":
            highCephalosporinase = true;
            break;
          case "INDUCIBLE CEPHALOSPORINASE":
            cephalosporinase = true;
            break;
          case "HCASE + PENICILLINASE":
            highCephalosporinase = true;
            penicillinase = true;
            break;
          case "NOT ENZYMATIC RESISTANCE":
            nonEnzymaticResistance = true;
            break;
          case "INDUCIBLE CEPHALOSPORINASE":
            cephalosporinase = true;
            break;
          case "E.S.B.L.":
            esbl = true;
            break;
          case "CARBAPENEMASE":
            carbapenemase = true;
            break;
        }
      }

      if (test == "Production of carbapenemase") {
        if (value == "Yes") {
          carbapenemase = "Yes";
        }
      }
    });

    mechanisms.push(["Céphalosporinase haut niveau", highCephalosporinase]);
    mechanisms.push(["Céphalosporinase", cephalosporinase]);
    mechanisms.push(["ESBL", esbl]);
    mechanisms.push(["Perte porine OprD2", oprd2]);
    mechanisms.push([
      "Résistance non enzymatique (efflux)",
      nonEnzymaticResistance,
    ]);
    mechanisms.push(["Carbapénémase", carbapenemase]);
    // enteroccocus
  } else if (ast.genus.match("Enterococcus")) {
    const highResistanceGenta =
      ("GENTAMICIN 10µg" in ast.finalAntibiogram.antibiotics &&
        ast.finalAntibiogram.antibiotics["GENTAMICIN 10µg"].siri.match(
          "High level resistance"
        )) ||
      ("GENTAMICIN 500µg" in ast.finalAntibiogram.antibiotics &&
        ast.finalAntibiogram.antibiotics["GENTAMICIN 500µg"].siri.match(
          "High level resistance"
        ));
    const vre = checkMappedSiri(ast.finalAntibiogram, "VANCOMYCIN 5µg", ["R"]);

    mechanisms.push(["Haute résistance à la Gentamicine", highResistanceGenta]);
    mechanisms.push(["Enterococcus Vancomycine Résistant", vre]);
  }

  return mechanisms;
}
