import sha256 from "js-sha256";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { initApp, checkGoogleAuth, loggedIn } from "./auth";
import { logsToAsts } from "./ast";
import { generateAstSheet } from "./sheetCreation";
import { showMessage, showMessageLink, clearMessages } from "./utils";
// necessary not to break async and await keywords with parcel
import "babel-polyfill";
// script relies on google api js imported straight from export.html

let db;

/* This page is a hacky tool to allow the ASTapp team to retrieve AST from the
      logs and export them in a Google Spreadsheet */

function submitExport(event) {
  // Keep the form from making the browser try to navigate anywhere.
  event.preventDefault();
  const commaSeparatedValuesFromDiv = (divId: string): string[] => {
    const str = (document.getElementById(divId) as HTMLInputElement).value;
    if (!str) {
      return [];
    }
    return str.split(",").map(s => s.trim());
  }
  const astIds = commaSeparatedValuesFromDiv("astId");
  const astIdHashes = commaSeparatedValuesFromDiv("astIdHash");
  const limit = parseInt((document.getElementById("limit") as HTMLInputElement).value);
  if (isNaN(limit) || limit < 1) {
    showMessage("Invalid limit value.");
    return;
  }
  if (astIds.length > 0) {
    runQueryByAstIdAndExport(astIds, limit);
  } else if (astIdHashes.length > 0) {
    runQueryByAstIdHashAndExport(astIdHashes, limit);
  }
}

window.addEventListener("load", async function() {
  await initApp({ useGapi: true });
  db = firebase.firestore();
  document
    .getElementById("query-form")
    .addEventListener("submit", submitExport);

  document
    .getElementById("daily-summary-form")
    .addEventListener("submit", function(event) {
      // Keep the form from making the browser try to navigate anywhere.
      event.preventDefault();
      const numDays = parseInt(document.getElementById("number-of-days").value);
      runDailyReport(numDays);
    });
});

const runQueryByAstIdAndExport = async function(astIds, limit) {
  runQueryByAstIdHashAndExport(astIds.map(sha256), limit, astIds);
}

const runQueryByAstIdHashAndExport = async function(astIdHashes: string[], limit: number, astIds: string[] = []) {
  // astIds is optional so that we can still create a spreadsheet even if we
  // don't know the AST ID.
  clearMessages();
  if (!loggedIn()) {
    showMessage("Please sign in first.");
    return;
  }
  let entries;
  let logsData = [];

  for (const singleID of astIdHashes) {
      entries = await db
        .collection("remoteLogs")
        .where("astIdHash", "==", singleID)
        .orderBy("timestampMs", "desc")
        .limit(limit)
        .get();
    
      if (!entries.size) {
        showMessage(`Couldn't fetch data for AST hash ${singleID}`);
        continue;
      }
      showMessage(`Records received for AST hash ${singleID}: ${entries.size}`);
      entries.forEach(entry => logsData.push(entry.data()))
  }

  /* Show the raw log in the "data" tag */
  let dataElement = document.getElementById("data") as HTMLPreElement;
  dataElement.innerHTML = "";
  logsData.forEach(entry => {
    let data = JSON.stringify(entry, null, 2);
    data += ",\n";
    const t = document.createTextNode(data);
    dataElement.appendChild(t);
  });

  try {
    let asts = logsToAsts(logsData, astIds)
      // filter out ASTs that are not complete
      .filter(ast => ast.complete);
    if (!asts.length) {
      throw Error("No complete AST");
    }
    astsToSpreadsheet(asts, astIds);
  } catch (err) {
    console.error(err);
    showMessage("Something went wrong parsing data: " + err.message);
    return;
  }
};

function formatDateNow() {
  let now = new Date(Date.now());
  return [now.getDate(), now.getMonth() + 1, now.getFullYear()]
    .map(e => e.toString())
    .join("-");
}

async function addConditionalFormatting(spreadsheet)
{
  try {
    const allSheets = spreadsheet.result.sheets;

    var requests = [];

    allSheets.forEach((sheet) =>
      requests.push([
      {
        addConditionalFormatRule: {
          rule: {
            ranges: [
              {
                sheetId: sheet.properties.sheetId,
                startRowIndex: 6,
                endRowIndex: 100,
                startColumnIndex: 4,
                endColumnIndex: 5
              }
            ],
            booleanRule: {
              condition: {
                type: "TEXT_EQ",
                values: [
                  {
                    userEnteredValue: "OK"
                  }
                ]
              },
              format: {
                backgroundColor: {
                  green: 225/255,
                  red: 183/255,
                  blue: 205/255
                }
              }
            }
          }
        }
      },
      {
        addConditionalFormatRule: {
          rule: {
            ranges: [
              {
                sheetId: sheet.properties.sheetId,
                startRowIndex: 6,
                endRowIndex: 100,
                startColumnIndex: 4,
                endColumnIndex: 5
              }
            ],
            booleanRule: {
              condition: {
                type: "TEXT_EQ",
                values: [
                  {
                    userEnteredValue: "KO"
                  }
                ]
              },
              format: {
                backgroundColor: {
                  green: 204/255,
                  red: 244/255,
                  blue: 204/255
                }
              }
            }
          }
        }
      },
      {
        addConditionalFormatRule: {
          rule: {
            ranges: [
              {
                sheetId: sheet.properties.sheetId,
                startRowIndex: 6,
                endRowIndex: 100,
                startColumnIndex: 8,
                endColumnIndex: 9
              }
            ],
            booleanRule: {
              condition: {
                type: "TEXT_EQ",
                values: [
                  {
                    userEnteredValue: "OK"
                  }
                ]
              },
              format: {
                backgroundColor: {
                  green: 225/255,
                  red: 183/255,
                  blue: 205/255
                }
              }
            }
          }
        }
      },
      {
        addConditionalFormatRule: {
          rule: {
            ranges: [
              {
                sheetId: sheet.properties.sheetId,
                startRowIndex: 6,
                endRowIndex: 100,
                startColumnIndex: 8,
                endColumnIndex: 9
              }
            ],
            booleanRule: {
              condition: {
                type: "TEXT_EQ",
                values: [
                  {
                    userEnteredValue: "KO"
                  }
                ]
              },
              format: {
                backgroundColor: {
                  green: 204/255,
                  red: 244/255,
                  blue: 204/255
                }
              }
            }
          }
        }
      }
    ])
  );

    var batchUpdateRequest = {requests: requests}

    await gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheet.result.spreadsheetId,
      resource: batchUpdateRequest
    });
  }catch(err) 
  {
    throw(err);
  }
}

async function astsToSpreadsheet(asts, astIds) {
  try {
    await checkGoogleAuth();
  } catch (err) {
    console.error(err);
    showMessage("Auth error. Try signing out and back in.");
    return;
  }
  try {
    let sheets = await Promise.all(
      asts.map((ast, index) => generateAstSheet(ast, index))
    );
    const response = await gapi.client.sheets.spreadsheets.create({
      properties: {
        title: astIds.length > 0 ? astIds.join(" - ") : "AST logs exports " + formatDateNow()
      },
      sheets
    });
    const spreadsheetUrl = response.result.spreadsheetUrl;

    await addConditionalFormatting(response);
   
    showMessageLink(`Spreadsheet created`, spreadsheetUrl);
  } catch (err) {
    clearMessages();
    console.error(err);
    showMessage("Something went wrong: ");
    showMessage(err.message);
    showMessage(err.result.error.message);
  }
}

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
/**
 * Compiles a summary of ASTs started and completed during the last `numDays` using the
 * alpha version of the app.
 */
const runDailyReport = async function(numDays) {
  clearMessages();
  showMessage(`working...`);
  const dataElement = document.getElementById("data");
  dataElement.innerHTML = "";
  if (!loggedIn) {
    showMessage("Please sign in first.");
    return;
  }

  const startTimeMs = Date.now() - numDays * DAY_IN_MILLIS;
  const startTimestamp = firebase.firestore.Timestamp.fromMillis(startTimeMs);

  const query = action =>
    db
      .collection("remoteLogs")
      .where("logEntry.action", "==", action)
      .where("timestampMs", ">", startTimestamp)
      .orderBy("timestampMs", "desc");

  const startedQuery = query("START_FLOW");
  const completedQuery = query("ES/completeFlow");
  let started;
  let completed;
  try {
    started = await startedQuery.get();
    completed = await completedQuery.get();
  } catch (e) {
    console.log(e);
    showMessage(e);
    return;
  }
  const docsFromAlphaVersions = started.docs.filter(doc =>
    doc.data().logEntry.payload.version.endsWith("alpha")
  );
  const summary = {};
  docsFromAlphaVersions.forEach(doc => {
    summary[doc.data().astIdHash] = {
      sessions: [],
      started: [],
      completed: [],
      versions: []
    };
  });
  docsFromAlphaVersions.forEach(doc => {
    const data = doc.data();
    const ast = summary[data.astIdHash];
    ast.sessions.push(data.sessionId);
    ast.started.push(data.timestampMs.toDate());
    ast.versions.push(data.logEntry.payload.version);
  });
  completed.forEach(entry => {
    const data = entry.data();
    const ast = summary[data.astIdHash];
    // Only if it matches an AST ID and session ID from the alpha entries.
    if (ast && ast.sessions.includes(data.sessionId)) {
      ast.completed.push(data.timestampMs.toDate());
    }
  });

  let startedCount = 0;
  let uniqueStarted = 0;
  let completedCount = 0;
  let uniqueCompleted = 0;
  for (const astIdHash in summary) {
    const startedLen = summary[astIdHash].started.length;
    const completedLen = summary[astIdHash].completed.length;
    if (startedLen > 0) {
      startedCount += startedLen;
      uniqueStarted++;
    }
    if (completedLen > 0) {
      completedCount += completedLen;
      uniqueCompleted++;
    }
  }

  const summaryText = JSON.stringify(summary, null, 2);
  const t = document.createTextNode(summaryText);
  dataElement.appendChild(t);

  clearMessages();
  showMessage(`Since: ${startTimestamp.toDate()}`);
  showMessage(`Started: ${startedCount} (unique: ${uniqueStarted})`);
  showMessage(`Completed: ${completedCount} (unique: ${uniqueCompleted})`);
};
