import { extractMechanismFromAst } from "./ast.js";

/* Functions to create the spreadsheet exported by export.html */

const sheetFontFamily = "Rubik";

function colorToFraction(colors) {
  return {
    red: colors.red / 255,
    green: colors.green / 255,
    blue: colors.blue / 255,
    alpha: colors.alpha
  };
}

function stringCell(s, type) {
  if ((s == "") && type && type == "emptyValue")
  {
    return {      
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        textFormat: {
          fontFamily: sheetFontFamily
        }
      }
    }
  }

  let cellHorizontalAlignment = "CENTER";

  if(type && type == "astInfoValue")
  {
      cellHorizontalAlignment = "LEFT";
  }

  let cell = {
    userEnteredValue: {
      stringValue: s
    },
    userEnteredFormat: {
      horizontalAlignment: cellHorizontalAlignment,
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };

  if (type && type.match(/[Hh]eader/)) {
    let fontWeightBold = false;
    cellHorizontalAlignment = "LEFT";

    if (type == "atbColumnHeader")
    {
      cellHorizontalAlignment = "CENTER";
    }

    if (type == "atbShortNameHeader")
    {
      fontWeightBold = true;
    }

    cell.userEnteredFormat = {
      backgroundColor: colorToFraction({
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1
      }),
      horizontalAlignment: cellHorizontalAlignment,
      verticalAlignment: "MIDDLE",
      textFormat: {
        bold: fontWeightBold,
        fontFamily: sheetFontFamily,
        foregroundColor: colorToFraction({
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        })
      },
      wrapStrategy: "WRAP"
    };
  }

  return cell;
}

function formulaCell(f, rowIndex, horizontalAlignment = "CENTER") {
  let row = rowIndex + 1;

  let cell = {
    userEnteredValue: {
      formulaValue: f.replaceAll("%ROW", row)
    }
  };
  
  cell.userEnteredFormat = {
    horizontalAlignment: horizontalAlignment,
    textFormat: {
      fontFamily: sheetFontFamily
    }
  };
  
  return cell;
}

function checkBoxCell(b) {
  return {
    userEnteredValue: {
      boolValue: b
    },
    dataValidation: {
      condition: { type: "BOOLEAN" },
      strict: true
    },
    userEnteredFormat: {
      horizontalAlignment: "CENTER",
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };
}

function numberCell(n) {
  return {
    userEnteredValue: {
      numberValue: n
    },
    userEnteredFormat: {
      horizontalAlignment: "CENTER",
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };
}
export async function generateAstSheet(ast, sheetId) {
  if (!ast.complete) {
    throw Error("Incomplete AST");
  }

  let rowIndex = 0;
  let dataGrid = [];

  function pushToDataGrid(rows, hiddenColumns) {
    let maxEditRow = rowIndex + rows.length;
    let numberOfColumns = Math.max(...rows.map(row => row.values.length));
    let columnMetadata = [];
    for (let i = 0; i < numberOfColumns; i++) {
      columnMetadata.push({
        pixelSize: 150,
        hiddenByUser: hiddenColumns && hiddenColumns.includes(i)
      });
    }
    dataGrid.push({
      startRow: rowIndex,
      startColumn: 0,
      rowData: rows,
      columnMetadata
    });

    rowIndex = Math.max(rowIndex, maxEditRow);
  }

  // add ast creation date
  pushToDataGrid([
    {
      values: [stringCell("AST Creation Date", "header"), stringCell(formationCreationDate(ast.startTime), "astInfoValue")]
    }
  ]);

  function formationCreationDate(creationDate) {
    // let now = new Date(Date.now());
    return [creationDate.getDate(), creationDate.getMonth() + 1, creationDate.getFullYear()]
      .map(e => e.toString())
      .join("/");
  }

  // add bacteria species
  pushToDataGrid([
    {
      values: [stringCell("Species", "header"), stringCell(ast.species, "astInfoValue")]
    }
  ]);

  // add sample type
  pushToDataGrid([
    {
      values: [stringCell("Sample type", "header"), stringCell(ast.sampleType, "astInfoValue")]
    }
  ]);

  let imageUrl = ast.getImageUrl();
  let imageUrlToText = imageUrl.replace("\"","\\\"");
  let imageFormulaTemplate = "=LIEN_HYPERTEXTE(\"imageUrl\";\"imageUrlToText\")";
  let imageFormula = imageFormulaTemplate.replace("imageUrl", imageUrl).replace("imageUrlToText", imageUrlToText);
  // add picture link and empty line
  pushToDataGrid([
    {
      values: [stringCell("Image", "header"), formulaCell(imageFormula, "astInfoValue", "LEFT")]
    },
    {
      values: [stringCell("")]
    }
  ]);

  const columnHeaders = [
    "",
    "",
    "Antibiogo diameters",
    "Manual diameters",
    "Diameters comparison",
    "Gross categorization",
    "Interpretive reading Expert System",
    "Interpretive reading Microbiologist",
    "Interpretive reading comparison"
  ]

  // add header for each columns
  pushToDataGrid([
    {
      values: columnHeaders.map(value => stringCell(value, "atbColumnHeader"))
    }
  ]);

  function restoreFullName(name) {
    // To log into firebase we had to replace all dots in dictionary keys by dashes
    // To match antibiotics name stored in an array with the dictionary keys, we
    // have to reproduce this transformation. (RN app only)
    //
    // The Kotlin app doesn't perform the transformation in the first place, so
    // we don't need to reverse it and simply return the name as-is.
    if (ast.isKotlin) {
      return name;
    }

    return name.replace(/\./g, "-");
  }

  ast.finalAntibiotics.forEach(ab =>
    pushToDataGrid([
      {
        values: [
          stringCell(ab.fullName, "atbHeader"),
          stringCell(ab.label.text, "atbShortNameHeader"),
          numberCell(Math.round(ab.inhibition.diam / ast.pixelPerMM)),
          stringCell(
            "", "emptyValue" /* manual diameters */
          ), 
          formulaCell(
            "=SI(ESTVIDE(D%ROW),\"\",SI(C%ROW=D%ROW,\"OK\",\"KO\"))" /* diameters comparison */,
            rowIndex
          ), 
          stringCell(
            ast.finalAntibiogram.antibiotics[restoreFullName(ab.fullName)].sir
          ),
          stringCell(
            ast.finalAntibiogram.antibiotics[restoreFullName(ab.fullName)].siri
          ),
          stringCell(
            "", "emptyValue" /* interpretive reading microbiologist */
          ), 
          formulaCell(
            "=SI(ESTVIDE(H%ROW),\"\",SI(G%ROW=H%ROW,\"OK\",\"KO\"))" /* interpretive reading comparison */,
            rowIndex
          ) 
        ]
      }
    ])
  );
  pushToDataGrid([
    {
      values: [stringCell("")]
    }
  ]);

  extractMechanismFromAst(ast).forEach((mechanism) =>
    pushToDataGrid(
        [
        {
            values: [stringCell(mechanism[0], "mechanismHeader")].concat(
            ["", "", "", "", ""]
                .map(cellValue => stringCell(cellValue))
                .concat([mechanism[1], false].map(checkBoxCell))
            )
        }
        ],
        [6]
    )
  );

  const esColumnIndex = columnHeaders.indexOf("Interpretive reading Expert System");

  return {
    properties: {
      title: ast.astId || ast.astIdHash,
      sheetId
    },
    data: dataGrid,
    protectedRanges: [
      {
        range: {
          sheetId,
          // Protecting the expert system columns (which is also hidden)
          // as it shouldn't be visible to the microbiologist who will
          // fill the spreadsheet
          startColumnIndex: esColumnIndex,
          endColumnIndex: esColumnIndex + 1
        },
        warningOnly: false
      }
    ]    
  };
}
