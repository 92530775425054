import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// necessary not to break async and await keywords with parcel
import "babel-polyfill";
// script relies on google api js imported straight from export.html

/* Auth script used by both export and index.html */
// TODO: encapsulate the auth as a React component (currently tight dependency with html DOM)

const CLIENT_ID =
  "892182270211-7vjfk4n36c8s96bjqer4vpe591dmurho.apps.googleusercontent.com";
const API_KEY = "AIzaSyBi9XfPz8PEcCptrMwCfQsbV5Q-7L0nUpI";

const DISCOVERY_DOCS = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4"
];
const SCOPES = "https://www.googleapis.com/auth/drive.file";

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: "astapp-6b3bc.firebaseapp.com",
  databaseURL: "https://astapp-6b3bc.firebaseio.com",
  projectId: "astapp-6b3bc",
  storageBucket: "astapp-6b3bc.appspot.com",
  messagingSenderId: "892182270211",
  appId: "1:892182270211:web:81bf58349a542228691936",
  measurementId: "G-W3LF10NC92"
};

let accessToken;
let isLoggedIn;

firebase.initializeApp(firebaseConfig);

export const initApp = function(options) {
  if (options && options.useGapi) {
    gapi.load("client:auth2");
  }
  return firebase.auth().onAuthStateChanged(
    function(user) {
      if (user) {
        // User is signed in.
        isLoggedIn = true;
        var email = user.email;
        document.getElementById("email").textContent = email;
        document.getElementById("sign-in-out").textContent = "Sign out";
        document
          .getElementById("sign-in-out")
          .removeEventListener("click", signIn);
        document
          .getElementById("sign-in-out")
          .addEventListener("click", signOut);
      } else {
        // User is signed out.
        isLoggedIn = false;
        document.getElementById("email").textContent = "";
        document.getElementById("sign-in-out").textContent = "Sign in";
        document
          .getElementById("sign-in-out")
          .removeEventListener("click", signOut);
        document
          .getElementById("sign-in-out")
          .addEventListener("click", signIn);
      }
    },
    function(error) {
      console.error(error);
    }
  );
};

export function loggedIn() {
  return isLoggedIn;
}

function initGoogleClient() {
  return gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES
  });
}

function signIn() {
  // Start a sign in process for an unauthenticated user.
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  provider.addScope(SCOPES);
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      accessToken = result.credential.accessToken;
    })
    .catch(function(error) {
      console.error(error);
    });
}

function signOut() {
  firebase.auth().signOut();
}

export async function checkGoogleAuth() {
  if (!gapi.client.sheets) {
    await initGoogleClient();
  }
  if (!accessToken) {
    let authResponse = gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse(true);
    if (!authResponse) { // needs to refresh the Oauth
      authResponse = await gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .reloadAuthResponse();
    }
    accessToken = authResponse.access_token
  }
  gapi.client.setToken({ access_token: accessToken });
}
