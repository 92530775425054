// UI functions used by both export.html and index.html

// Shows a message to the user.
export const showMessage = function(msg) {
  const messages = document.getElementById("messages");
  messages.appendChild(document.createTextNode(msg));
  messages.appendChild(document.createElement("BR"));
};

export const clearMessages = function() {
  const messages = document.getElementById("messages");
  messages.innerHTML = "";
};

export const showMessageLink = function(msg, url) {
  const messages = document.getElementById("messages");
  let a = document.createElement("a");
  a.appendChild(document.createTextNode(msg));
  a.href = url;
  a.title = msg;
  a.target = "_blank";
  messages.appendChild(a);
};
